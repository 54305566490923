/* stylelint-disable */

list[demo] { padding: 40px; }
demo_wrap { display: flex; gap:10px; padding: 20px; flex-wrap: wrap; align-items: center; overflow: hidden; position: relative; box-shadow: 0 0 0 1px var(--border-color); background: var(--background); flex-shrink: 0; }
demo_wrap[fab] { height: 250px; }
demo_column { margin: 20px; display: flex; flex-wrap: wrap; flex-direction: column; align-items: flex-start; padding: 30px; border-radius: 15px; background: var(--hover) }

grid { position: relative; /* padding:20px; */ display: flex; flex-direction: column; flex-shrink: 0; width: 100%; flex-shrink: 0; }
    
    grid.no_result { visibility: hidden; height: 0; }
    
    grid toolbar { position: sticky; top: 0 }
    
    grid wrap { position: relative; display: flex; flex-wrap: wrap; align-content: flex-start; padding: 20px; display: grid; grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));gap:var(--gap); }
    
    /* grid wrap item { overflow: hidden; cursor: pointer; padding: 15px;height: 100px;width: 100px; flex-direction: column; transition: .125s; position: relative; align-items: center; justify-content: center; display: flex; animation: appera forwards .125s; background: var(--hover);  border-radius: 10px; } */
    
    grid wrap item tag { display: none; }
    
    grid wrap item.selected {background: var(--main-color); color: #fff;}


    
view scroller {overflow: auto; overflow:overlay;    scroll-behavior: smooth; -webkit-overflow-scrolling: touch; display: flex; height: 100%; flex-direction: column; z-index: 1; }






grid { position: relative; /* padding:20px; */ display: flex; flex-direction: column; flex-shrink: 0; width: 100%; flex-shrink: 0; }

grid.no_result { visibility: hidden; height: 0; }

grid toolbar { position: sticky; top: 0 }

grid wrap { position: relative; display: flex; flex-wrap: wrap; align-content: flex-start; padding: 20px; display: grid; grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); }

grid wrap[mini] { grid-template-columns: repeat(auto-fill, minmax(40px, 1fr)); padding: 0; margin: 20px; overflow: hidden; grid-gap: 1px; }
grid wrap[mini] div { font-size: 18px; height: 40px; display: flex; align-items: center; justify-content: center; box-shadow: 0 0 0 1px var(--border-color); background: var(--background); }




grid wrap item { overflow: hidden; cursor: pointer; padding: 15px; width: 100%; flex-direction: column; transition: .125s; position: relative; align-items: center; justify-content: center; display: flex;  background: var(--hover); border-radius: 10px; }

/*grid wrap item:active .material_ink circle{fill:var(--main-color); opacity:1}*/

grid wrap item.selected { background: var(--main-color); color: #fff; }

grid wrap item text { white-space: nowrap; overflow: hidden; text-overflow: ellipsis;  width: 100%; text-align: center; }

grid wrap item:hover { background-image: var(--over-color) }


grid wrap item:active icon { transform: translateY(1px); }

grid wrap item icon, grid wrap item icon_color { margin: 0 0 10px }

grid wrap item tag { display: none }





.grouped_box { /* display: flex; */ flex-direction: column; overflow: hidden; columns: 5 400px; column-rule: 1px var(--border-color) solid; column-gap: 20px; }

.grouped_box_wrap { display: inline-flex; flex-direction: column; width: 100%; float: left; break-inside: avoid; }


.palette {border-radius: 5px; overflow: hidden; display: flex; position: relative;}
div.palette div {min-width: 24px; height: 32px;}

/* stylelint-enable */
